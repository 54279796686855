import { editCart, quantityOperation, removeProduct } from "@catalogUtils/cart.js";
import { validateForm }     from "@catalogUtils/validate.js";
import { handleParsePhone } from "@catalogUtils/parsePhone.ts";
import { byeOneClickCTP }   from "@catalogUtils/byeOneClick.js";

function runCartModalWindowFuncs() {
    editCart();
    removeProduct();
    quantityOperation();
    validateForm();
    handleParsePhone();
    byeOneClickCTP();
}

export { runCartModalWindowFuncs };