import { checkedAllInputs }           from "@catalogUtils/checkedAllInputs.js";
import { removeProductsFromWishlist } from "@catalogUtils/wishlist.js";

function runOtherFuncs() {
    checkedAllInputs(
        '.create-order__products-inner',
        '.create-order__check-all input',
        '.create-order__products-list'
    );
    checkedAllInputs(
        '.wishlist__products',
        '.create-order__checkboxes input',
        '.wishlist__products-list'
    );

    removeProductsFromWishlist();
}

export { runOtherFuncs };