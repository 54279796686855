import { document_var } from "@catalogUtils/vars.js";

const setCookieExpiration = (numSeconds) => {
    const currentDate = new Date(),
        expirationDate = new Date(currentDate.getTime() + numSeconds * 1000);

    return expirationDate.toUTCString();
};

function saveCookie(cookieName, cookieValue, expires = 86400) {
    document_var.cookie = `${cookieName}=${cookieValue}; path=/; expires=${setCookieExpiration(expires)}`;
}

export { saveCookie };