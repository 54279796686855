'use strict';

import { showSomeContent }                        from "@catalogUtils/showSomeContent.js";
import { setDeviceType }                          from "@catalogUtils/deviceType.js";
import { search }                                 from "@catalogUtils/search";
import { languageMenu }                           from "@catalogUtils/languageMenu.js";
import { lazyLoadImgs }                           from "@catalogUtils/lazyLoad.js";
import { toggleHeader }                           from "@catalogUtils/toggleHeader.js";
import { validateForm }                           from "@catalogUtils/validate.js";
import { accountFormAddActive, showAccountPopup } from "@catalogUtils/account.js";
import {
    getParametersFromWindow, showErrorInConsole
}                                                 from "@catalogUtils/helpers";
import { runOtherFuncs }                          from "@catalogUtils/runOtherFuncs.js";
import { dynamicLoadContent }                     from "@catalogUtils/dynamicLoadContent.js";
import { index_php_key }                          from "@catalogUtils/vars.js";

document.addEventListener('DOMContentLoaded', async () : Promise<void> => {
    const windowParameters = getParametersFromWindow();
    let pageType : string = '',
        deviceType : string = '';

    if ('page_type' in windowParameters) {
        pageType = windowParameters['page_type'] as string;
    }

    if ('device_type' in windowParameters) {
        deviceType = windowParameters['device_type'] as string;
    }

    import('@catalogUtilsHelpers/collapse')
        .then(module => module.handleCollapse())
        .catch(error => showErrorInConsole(error.message));

    if (pageType == 'home' || pageType == 'product_section') {
        import('@catalogUtilsHelpers/carousel')
            .then(module => {
                module.handleCarousel({
                    ride: true,
                });
            })
            .catch(error => showErrorInConsole(error.message));
    } else if (pageType == 'cart') {
        import('@catalogUtils/shippingContent')
            .then(module => module.handleShipping())
            .catch(error => showErrorInConsole(error.message));
    } else if (pageType == 'category') {
        import('@catalogUtils/categorySortList')
            .then(module => module.handleSortList())
            .catch(error => showErrorInConsole(error.message));

        import('@catalogUtils/ocfilter')
            .then(module => module.handleOCFilter())
            .catch(error => showErrorInConsole(error.message));

        import('@catalogUtils/showPageDescr')
            .then(module => module.showPageDescr())
            .catch(error => showErrorInConsole(error.message));

        import('@catalogUtils/moveSomeContent.js')
            .then(module => module.moveSomeContent('.category__top-filter-chosen', '.category__top-filter-chosen-inner'))
            .catch(error => showErrorInConsole(error.message));

        dynamicLoadContent(
            'data-ajax-load-cat-prods',
            index_php_key + 'product/category.loadMoreProducts',
            '.category__products-list .prods__list'
        );
    } else if (pageType == 'product') {
        import('@catalogUtilsHelpers/carousel')
            .then(module => module.handleCarousel())
            .catch(error => showErrorInConsole(error.message));

        import('@catalogUtils/modalProdImageGallery')
            .then(module => module.handleImageGallery(
                '.product__main-imgs-container .carousel-inner',
                '.carousel-item'
            ))
            .catch(error => showErrorInConsole(error.message));

        import('@catalogUtils/rating.js')
            .then(module => module.setRating())
            .catch(error => showErrorInConsole(error.message));

        import('@catalogUtils/helpers')
            .then(module => module.scrollToAnchor('.product__top-link'))
            .catch(error => showErrorInConsole(error.message));

        import('@catalogUtils/productReview.js')
            .then(module => {
                module.loadMoreReviews();
                module.sendProductReview();
                module.sendAnswerForReview();
                module.loadReviewAnswers();
            })
            .catch(error => showErrorInConsole(error.message));

        await import('@catalogUtils/loadDynamicIframe.js')
            .then(module => module.loadDynamicIframe())
            .catch(error => showErrorInConsole(error.message));
    } else if (pageType == 'login') {
        import('@catalogUtils/login.js')
            .then(module => module.login('.login__form'))
            .catch(error => showErrorInConsole(error.message));
    }

    if (pageType == 'login' || pageType == 'product' || pageType == 'cart' || pageType == 'contact') {
        import('@catalogUtils/parsePhone.ts')
            .then(module => module.handleParsePhone())
            .catch(error => showErrorInConsole(error.message));
    }

    if (pageType != 'cart') {
        await import('@catalogUtilsHelpers/offcanvas')
            .then(module => module.handleOffcanvas())
            .catch(error => showErrorInConsole(error.message));

        await import('@catalogUtils/cart.js')
            .then(module => {
                module.addToCart();
                module.quantityOperation();
                module.showCartModal();
            })
            .catch(error => showErrorInConsole(error.message));
    }

    if (pageType == 'category' || pageType == 'wishlist' || pageType == 'account' || pageType == 'blog') {
        import('@catalogUtils/loadMoreContent.js')
            .then(module => {
                module.loadMoreContent('.category__products');
                module.loadMoreContent('.wishlist__products');
                module.loadMoreContent('.account__item--last-orders', false, '#last-orders');
                module.loadMoreContent('.blog-list__articles');
            })
            .catch(error => showErrorInConsole(error.message));
    }

    if (pageType == 'home' || pageType == 'category' || pageType == 'product') {
        dynamicLoadContent('data-ajax-load-products', index_php_key + 'extension/some_products/module/products.loadProducts');
    }

    if (pageType == 'home' || pageType == 'category' || pageType == 'product' || pageType == 'wishlist') {
        import('@catalogUtils/wishlist.js')
            .then(module => module.wishlist('.prods__wishlist'))
            .catch(error => showErrorInConsole(error.message));
    }

    if (pageType == 'product' || pageType == 'blog') {
        if (deviceType == 'computer') {
            import('@catalogUtils/zoomCommonImages')
                .then(module => module.handleZoom())
                .catch(error => showErrorInConsole(error.message));
        } else {
            import('@catalogUtils/modalCommonImages')
                .then(module => module.handleShowImage())
                .catch(error => showErrorInConsole(error.message));
        }
    }

    showSomeContent();
    setDeviceType();
    search();
    languageMenu();
    lazyLoadImgs();
    toggleHeader();
    validateForm();
    accountFormAddActive();
    runOtherFuncs();
    showAccountPopup();
    dynamicLoadContent('data-ajax-load-content', index_php_key + 'common/footer.loadContent');
});