import { arrayFrom, findElems } from "@catalogUtils/helpers";

let pattern = '+38 (___) ___-__-__';

const setSelection = (phone : HTMLInputElement, position : number) : void => {
    phone.selectionStart = position;
    phone.selectionEnd = position;
}

const findPositionSelection = (string : string) : number => {
    let indexLetter = 0;

    while (typeof string[indexLetter] != 'undefined' && string[indexLetter] != '_' && string.length > indexLetter) {
        indexLetter++;
    }

    return indexLetter;
};

function parsePhone(_e : InputEvent, phone : HTMLInputElement) : void {
    console.log('target: ', _e);

    let pattern = '+38 (___) ___-__-__',
        tempPattern = pattern;

    let tempValue : string = phone.value.replace(/\D+/g, '').replace(/^38/, '');

    console.log('tempValue: ', tempValue);

    if (tempValue.length < 4) {
        return;
    }

    if (_e.inputType == 'deleteContentBackward') {
        const currentSelection = phone.selectionStart ?? 0;

        if (!currentSelection) {
            return;
        }


    } else {

    }

    const startPosition : number = +(phone.selectionStart ?? 5),
        isInvalidStartPosition : boolean = startPosition < 5;

    for (let numberIndex = 0; numberIndex < tempValue.length; numberIndex++) {
        tempPattern = tempPattern.replace(/_/, tempValue[numberIndex]);
    }

    phone.value = tempPattern;

    phone.selectionStart = phone.selectionEnd = findPositionSelection(tempPattern);


}

function handleParsePhone() : void {
    const inputFields = <HTMLInputElement[] | []>arrayFrom(findElems('input[type=tel]'));

    inputFields.forEach((phone : HTMLInputElement) : void => {
        phone.addEventListener('input', (e : Event) => parsePhone(e, phone));
        // phone.addEventListener('focus', (e : Event) => parsePhone(e, phone));
        // phone.addEventListener('blur', (e : Event) => parsePhone(e, phone));
        // phone.addEventListener('click', (e : Event) => parsePhone(e, phone));
    });
}

export { handleParsePhone };